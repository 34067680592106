<script>
	import posthog from "posthog-js";

	import LL from "@edible/shared/i18n-svelte";

	import { base } from "$app/paths";
	import { browser } from "$app/environment";
	import { beforeNavigate, afterNavigate } from "$app/navigation";

	import { PAGE_META_CONTEXT, PAGE_TITLE_DEFAULT } from "$lib/constants";

	import "../app.css";
	import { factory } from "$stores";
	import { setContext } from "svelte";

	if (browser) {
		beforeNavigate(() => posthog.capture("$pageleave"));
		afterNavigate(() => posthog.capture("$pageview"));
	}

	// Setup page meta store here and make available to underlying pages with context
	// so that they can update a single page_title|description as needed
	const pageMetaStore = factory.createPageMetaStore(
		PAGE_TITLE_DEFAULT,
		$LL.menu.meta.page_description_default()
	);

	setContext(PAGE_META_CONTEXT, pageMetaStore);
</script>

<div class="h-[100dvh] overflow-x-hidden">
	<slot />
</div>

<svelte:head>
	<link rel="icon" href={`${base}/favicon.png`} />
	<link href={`${base}/fonts/fonts.css`} rel="stylesheet" />
	<title>{$pageMetaStore.page_title}</title>
	<meta name="description" content={$pageMetaStore.page_description} />
</svelte:head>

<style>
	:global(html) {
		margin-right: calc(-1 * (100vw - 100%));
	}

	:global(body) {
		background-color: #ffffff;
	}
</style>
