//* Posthog key is not required at build time => we import them from "dynamic"
import { env } from "$env/dynamic/public";

import posthog from "posthog-js";
import { navigatorDetector } from "typesafe-i18n/detectors";

import { loadLocaleAsync, loadNamespaceAsync } from "@edible/shared/i18n-util.async";
import { setLocale } from "@edible/shared/i18n-svelte";
import { detectLocale } from "@edible/shared/i18n-util";

import { browser, version } from "$app/environment";

import { POSTHOG_REGISTER } from "$lib/constants";

const POSTHOG_APP_NAME = "menu-viewer";
const DISALLOWED_LOCATIONS = [
	"localhost",
	"127.0.0.1",
	"menus-dev.whatisedible.com", // Our staging environment
];

const DEFAULT_LOCAL = "en";

/** @type {import('./$types').LayoutLoad} */
export async function load() {
	const { PUBLIC_POSTHOG_KEY } = env;
	if (browser) {
		// Check for navigator locale or fallback to default defined on the server
		const locale = detectLocale(navigatorDetector) || DEFAULT_LOCAL;

		await loadLocaleAsync(locale);
		await loadNamespaceAsync(locale, "filter");
		await loadNamespaceAsync(locale, "menu");
		setLocale(locale);
	}

	if (browser && PUBLIC_POSTHOG_KEY && !DISALLOWED_LOCATIONS.includes(location.hostname)) {
		posthog.init(PUBLIC_POSTHOG_KEY, {
			api_host: "https://eu.posthog.com",
			capture_pageview: false,
			capture_pageleave: false,
			rageclick: true,
			persistence: "memory",
			persistence_name: `omi-menus-${version}`,
		});

		posthog.register_once({ [POSTHOG_REGISTER.APP]: POSTHOG_APP_NAME });
	}
}

export const trailingSlash = "always";
export const prerender = true;
