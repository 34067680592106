import type { FormattersInitializer } from "typesafe-i18n";
import type { Locales, Formatters } from "./i18n-types.js";

const list = (kind: "and" | "or") => (list: string[]) => {
	return list
		.map((item, ix, arr) => (arr.length > 1 && ix === arr.length - 1 ? `${kind} ${item}` : item))
		.join(", ");
};

export const initFormatters: FormattersInitializer<Locales, Formatters> = () => {
	const formatters: Formatters = {
		listAnd: (value: string[]) => list("and")(value),
	};

	return formatters;
};
